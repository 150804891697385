<template>
  <div class="print-shipping-order">
    <v-app>
      <v-container fluid>
        <!-- header -->
        <div class="text-h4 text-center font-weight-bold">
          {{ $t("__orderlist_shipping_order") }}
        </div>
        <v-row justify="space-between" class="text-center my-7">
          <v-col cols="4">
            <div class="font-weight-bold">{{ nowTime }}</div>
          </v-col>
          <v-col cols="4">
            <p class="text-h5 font-weight-bold">{{ store.storeName }}</p>
          </v-col>
          <v-col cols="4">
            <v-row class="flex-column">
              <div class="text-right">
                {{ $t("__orderlist_id") + ": " + orderID }}
              </div>
              <div>
                <img id="barcode" width="80%" />
              </div>
            </v-row>
          </v-col>
        </v-row>
        <!-- order info -->
        <!-- logistics -->
        <template v-if="shippingChannel.name">
          <p class="font-weight-black">
            {{
              $t("__order_shipping_deliveryinfo_name") +
                ": " +
                shippingChannel.name
            }}
          </p>
          <p class="font-weight-black">
            {{
              $t("__order_shipping_deliveryinfo_phonenumber") +
                ": " +
                shippingChannel.phone_number
            }}
          </p>
          <p class="font-weight-black">
            {{
              $t("__order_shipping_logistics_company_name") +
                ": " +
                shippingChannel.logisticsName
            }}
          </p>
          <p class="font-weight-black">
            {{
              $t("__order_shipping_deliveryinfo_address") +
                ": " +
                shippingChannel.postal_code +
                shippingChannel.address
            }}
          </p>
        </template>
        <!-- pick up -->
        <template v-else>
          <p class="font-weight-black">
            {{ $t("__order_contact_name") + ": " + order.name }}
          </p>
          <p class="font-weight-black">
            {{ $t("__order_contact_phonenumber") + ": " + order.phoneNumber }}
          </p>
          <p class="font-weight-black">
            {{ $t("__order_shipping_channel_pickup") }}
          </p>
        </template>
        <!-- items info -->
        <table width="100%" class="mt-8 text-left item-table">
          <tr>
            <th class="py-5">#</th>
            <th>{{ $t("__order_items") }}</th>
            <th>{{ $t("__order_items_id") }}</th>
            <th>{{ $t("__order_items_quantity") }}</th>
          </tr>
          <tr>
            <td colspan="6">
              <v-divider />
            </td>
          </tr>
          <tr v-for="(item, index) in items" :key="item.ID">
            <td>{{ index + 1 }}</td>
            <td colspan="2" class="d-flex flex-column py-3">
              <v-img
                :src="item.photo"
                v-if="item.photo"
                width="60"
                height="60"
                style="width: 60px;height:60px"
              />
              <p class="mb-0 pl-2">{{ item.name }}</p>
            </td>
            <td>{{ item.ID }}</td>
            <td>{{ item.qty }}</td>
          </tr>
        </table>
      </v-container>
    </v-app>
  </div>
</template>
<script>
import Jsbarcode from "jsbarcode";

export default {
  name: "PrintShippingOrder",
  props: {
    orderData: {
      type: Object
    },
    orderID: {
      type: String
    },
    orderConsumerName: {
      type: String
    }
  },
  data() {
    return {
      store: {},
      order: {},
      shippingChannel: {},
      items: [],
      nowTime: new Date().toLocaleString()
    };
  },
  methods: {
    loadStoreData() {
      if (this.orderData.store?.photo_urls) {
        this.store = {
          storePhoto: this.orderData.store.photo_urls[0],
          storeName: this.orderData.store.name
        };
      }
    },
    loadOrderData() {
      this.order = {
        name: this.orderConsumerName,
        phoneNumber: this.orderData.consumer.phone_number,
        shippingChannel: this.orderData.shipping_channel
      };
      if (this.orderData.shipping_channel === "logistics") {
        this.shippingChannel = this.orderData.shipping_delivery_info;
        this.shippingChannel.logisticsName = this.orderData.shipping_name;
      }
    },
    loadItems() {
      for (const item of this.orderData.items) {
        const foundIndex = this.items.findIndex(i => i.ID == item.item_id);
        if (foundIndex >= 0) {
          this.items.splice(foundIndex, 1, {
            photo: item.item.photo_urls ? item.item.photo_urls[0] : null,
            name: item.item.name,
            ID: item.item_id,
            qty: item.qty
          });
        } else {
          this.items.push({
            photo: item.item.photo_urls ? item.item.photo_urls[0] : null,
            name: item.item.name,
            ID: item.item_id,
            qty: item.qty
          });
        }
      }
    }
    // hidePhone(phone) {
    //   let num = "+886*****";
    //   let lastNum = phone.slice(-3);
    //   num = num.concat(lastNum);
    //   return num;
    // },
  },
  watch: {
    orderData: {
      deep: true,
      handler() {
        this.loadStoreData();
        this.loadOrderData();
        this.loadItems();
        Jsbarcode("#barcode", this.orderID);
      }
    }
  }
};
</script>
