<template>
  <v-card class="mx-auto" :loading="loading">
    <template slot="progress">
      <v-progress-linear
        color="primary darken-2"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title
        >{{ $t("__order") }}
        <span v-if="!isMobile">
          {{ " (" + orderId + ")" }}
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn class="mr-2" outlined @click="reloadOrder">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn outlined v-bind="attrs" v-on="on">
            <v-icon> mdi-printer</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group>
            <v-list-item @click="toPrintOrder">
              <v-list-item-icon>
                <v-icon>mdi-clipboard-list-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("__order") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="toPrintShippingOrder">
              <v-list-item-icon>
                <v-icon>mdi-truck</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("__orderlist_shipping_order")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="toPrintItems">
              <v-list-item-icon>
                <v-icon>mdi-tag-text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("__order_print_item")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text v-if="!loading">
      <table class="data_table">
        <tr v-if="isMobile">
          <th class="data_table__title">{{ $t("__order_id") }}</th>
          <td class="data_table__content">{{ orderId }}</td>
        </tr>
        <tr>
          <th class="data_table__title">{{ $t("__order_store") }}</th>
          <td class="data_table__content">{{ orderData.store.name }}</td>
        </tr>
        <tr>
          <th class="data_table__title">
            {{ $t("__order_items") }}
          </th>
          <td class="data_table__content" v-if="!isMobile">
            <v-simple-table dense class="outlined_table">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">{{ $t("__order_items_name") }}</th>
                    <th class="text-left">{{ $t("__order_items_price") }}</th>
                    <th class="text-left">
                      {{ $t("__order_items_quantity") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="orderItem in orderItems"
                    :key="orderItem.itemID"
                    class="color_tr"
                  >
                    <td>{{ orderItem.item.name }}</td>
                    <td>${{ orderItem.item.price }}</td>
                    <td>{{ orderItem.qty }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </tr>
        <tr v-if="isMobile">
          <td colspan="2">
            <v-simple-table dense class="outlined_table">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">{{ $t("__order_items_name") }}</th>
                    <th class="text-left">{{ $t("__order_items_price") }}</th>
                    <th class="text-left">
                      {{ $t("__order_items_quantity") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="orderItem in orderItems" :key="orderItem.itemID">
                    <td>{{ orderItem.item.name }}</td>
                    <td>${{ orderItem.item.price }}</td>
                    <td>{{ orderItem.qty }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </tr>
        <tr>
          <td colspan="2"><v-divider class="divider" /></td>
        </tr>
        <tr>
          <th class="data_table__title">{{ $t("__order_createtime") }}</th>
          <td class="data_table__content">{{ orderCreateTimeFormatted }}</td>
        </tr>
        <tr>
          <th class="data_table__title">{{ $t("__order_contact") }}</th>
          <td class="data_table__content">
            <table class="data_table">
              <tr>
                <th class="data_table__title">
                  {{ $t("__order_contact_name") }}
                </th>
                <td class="data_table__content">{{ orderConsumerName }}</td>
              </tr>
              <tr>
                <th class="data_table__title">
                  {{ $t("__order_contact_phonenumber") }}
                </th>
                <td class="data_table__content">
                  {{ orderData.consumer.phone_number }}
                </td>
              </tr>
              <tr>
                <th class="data_table__title">
                  {{ $t("__order_contact_email") }}
                </th>
                <td class="data_table__content">
                  {{ orderData.consumer.email }}
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <th class="data_table__title">
            {{ $t("__order_shipping_channel") }}
          </th>
          <td class="data_table__content">
            <table class="data_table">
              <tr>
                <th class="data_table__title">
                  {{
                    orderData.shipping_channel
                      ? $t(
                          `__order_shipping_channel_${orderData.shipping_channel}`
                        )
                      : "-"
                  }}
                </th>
                <td class="data_table__content">
                  {{ orderData.shipping_name ? orderData.shipping_name : "" }}
                </td>
              </tr>
              <template v-if="orderData.shipping_channel == 'logistics'">
                <tr>
                  <th class="data_table__title">
                    {{ $t("__order_shipping_logistics_number") }}
                  </th>
                  <td class="data_table__content">
                    {{
                      orderData.logistics_number
                        ? orderData.logistics_number
                        : "-"
                    }}
                  </td>
                </tr>
                <tr>
                  <th class="data_table__title">
                    {{ $t("__order_shipping_price") }}
                  </th>
                  <td class="data_table__content">
                    ${{
                      orderData.shipping_price ? orderData.shipping_price : 0
                    }}
                  </td>
                </tr>
                <template v-if="orderData.shipping_delivery_info">
                  <tr>
                    <th class="data_table__title">
                      {{ $t("__order_shipping_deliveryinfo_name") }}
                    </th>
                    <td class="data_table__content">
                      {{ orderData.shipping_delivery_info.name }}
                    </td>
                  </tr>
                  <tr v-if="orderData.shipping_delivery_info.phone_number">
                    <th class="data_table__title">
                      {{ $t("__order_shipping_deliveryinfo_phonenumber") }}
                    </th>
                    <td class="data_table__content">
                      {{ orderData.shipping_delivery_info.phone_number }}
                    </td>
                  </tr>
                  <tr v-if="orderData.shipping_delivery_info.address">
                    <th class="data_table__title">
                      {{ $t("__order_shipping_deliveryinfo_address") }}
                    </th>
                    <td class="data_table__content">
                      {{ orderData.shipping_delivery_info.postal_code }}
                      {{ orderData.shipping_delivery_info.address }}
                    </td>
                  </tr>
                </template>
              </template>
            </table>
          </td>
        </tr>
        <tr>
          <td colspan="2"><v-divider class="divider" /></td>
        </tr>
        <tr>
          <th class="data_table__title">{{ $t("__order_orderstatus") }}</th>
          <td class="data_table__content" v-if="!isMobile">
            <v-simple-table dense class="outlined_table">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t("__order_orderstatus_status") }}
                    </th>
                    <th class="text-left">
                      {{ $t("__order_orderstatus_time") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="status of statuses" :key="status.status">
                    <td>{{ $t(`__order_status_${status.status}`) }}</td>
                    <td>{{ status.timeFormatted }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </tr>
        <tr v-if="isMobile">
          <td colspan="2">
            <v-simple-table dense class="outlined_table">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t("__order_orderstatus_status") }}
                    </th>
                    <th class="text-left">
                      {{ $t("__order_orderstatus_time") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="status of statuses" :key="status.status">
                    <td>{{ $t(`__order_status_${status.status}`) }}</td>
                    <td>{{ status.timeFormatted }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </tr>
        <tr>
          <td colspan="2"><v-divider class="divider" /></td>
        </tr>
        <tr>
          <th class="data_table__title">
            {{ $t("__order_payment_method") }}
          </th>
          <td class="data_table__content">
            {{
              orderData["payment_method"]
                ? $t(`__order_payment_method_${orderData["payment_method"]}`)
                : "-"
            }}
          </td>
        </tr>
        <tr>
          <th class="data_table__title">{{ $t("__order_payments") }}</th>
          <td class="data_table__content" v-if="!isMobile">
            <v-simple-table dense class="outlined_table">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t("__order_payments_id") }}
                    </th>
                    <th class="text-left">
                      {{ $t("__order_payments_method") }}
                    </th>
                    <th class="text-left">
                      {{ $t("__order_payments_info") }}
                    </th>
                    <th class="text-left">
                      {{ $t("__order_payments_amount") }}
                    </th>
                    <th class="text-left">
                      {{ $t("__order_payments_time") }}
                    </th>
                    <th class="text-left">
                      {{ $t("__order_payments_status") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="payments.length">
                    <tr v-for="payment in payments" :key="payment.id">
                      <td>{{ payment.id }}</td>
                      <td>{{ $t(`__payment_method_${payment.method}`) }}</td>
                      <td>{{ payment.info }}</td>
                      <td>{{ payment.amount }}</td>
                      <td>{{ payment.timeFormatted }}</td>
                      <td>{{ $t(`__payment_status_${payment.status}`) }}</td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="6" class="text-center">
                        {{ $t("__order_payments_no_datas") }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </tr>
        <tr v-if="isMobile">
          <td colspan="2">
            <v-data-table
              dense
              :headers="paymentHeaders"
              :items-per-page="5"
              :items="payments"
            >
              <template slot="no-data">
                {{ $t("__order_payments_no_datas") }}
              </template>
              <template v-slot:[`item.method`]="{ item }">
                {{ $t(`__payment_method_${item.method}`) }}
              </template>
              <template v-slot:[`item.status`]="{ item }">
                {{ $t(`__payment_status_${item.status}`) }}
              </template>
            </v-data-table>
          </td>
        </tr>
        <tr>
          <th class="data_table__title">
            {{ $t("__order_transferinfos") }}
          </th>
          <td class="data_table__content" v-if="!isMobile">
            <v-simple-table dense class="outlined_table">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t("__order_transferinfos_id") }}
                    </th>
                    <th class="text-left">
                      {{ $t("__order_transferinfos_accountlast5digits") }}
                    </th>
                    <th class="text-left">
                      {{ $t("__order_transferinfos_amount") }}
                    </th>
                    <th class="text-left">
                      {{ $t("__order_transferinfos_time") }}
                    </th>
                    <th class="text-left">
                      {{ $t("__order_transferinfos_status") }}
                    </th>
                    <th class="text-left">
                      {{ $t("__order_transferinfos_actions") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="transferInfos.length">
                    <tr
                      v-for="transferInfo in transferInfos"
                      :key="transferInfo.id"
                    >
                      <td>{{ transferInfo.id }}</td>
                      <td>{{ transferInfo.accountLast5Digits }}</td>
                      <td>{{ transferInfo.amount }}</td>
                      <td>{{ transferInfo.timeFormatted }}</td>
                      <td>
                        {{
                          $t(
                            `__order_transferinfos_status_${transferInfo.status}`
                          )
                        }}
                      </td>
                      <td>
                        <template
                          v-if="
                            orderStatus != 'completed' &&
                              orderStatus != 'cancelled' &&
                              transferInfo.status === 'unconfirmed' &&
                              orderPaidAmount < orderData.price
                          "
                        >
                          <v-btn
                            small
                            color="primary"
                            class="ma-1"
                            @click="replyTransferInfo(transferInfo)"
                            >{{
                              $t("__order_transferinfos_actions_reply")
                            }}</v-btn
                          >
                        </template>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="6" class="text-center">
                        {{ $t("__order_transferinfos_no_datas") }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </tr>
        <tr v-if="isMobile">
          <td colspan="2">
            <v-data-table
              dense
              :headers="transferHeaders"
              :items="transferInfos"
              :items-per-page="5"
            >
              <template slot="no-data">
                {{ $t("__order_transferinfos_no_datas") }}
              </template>
              <template v-slot:[`item.status`]="{ item }">
                {{ $t(`__order_transferinfos_status_${item.status}`) }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <template
                  v-if="
                    orderStatus != 'completed' &&
                      orderStatus != 'cancelled' &&
                      item.status === 'unconfirmed' &&
                      orderPaidAmount < orderData.price
                  "
                >
                  <v-btn
                    small
                    color="primary"
                    class="ma-1"
                    @click="replyTransferInfo(item)"
                    >{{ $t("__order_transferinfos_actions_reply") }}</v-btn
                  >
                </template>
              </template>
            </v-data-table>
          </td>
        </tr>
        <tr>
          <td colspan="2"><v-divider class="divider" /></td>
        </tr>
        <tr>
          <th class="data_table__title">{{ $t("__order_price") }}</th>
          <td class="data_table__content">${{ orderData.price }}</td>
        </tr>
      </table>
    </v-card-text>
    <v-card-actions v-if="!loading">
      <v-spacer />
      <template v-if="orderStatus != 'completed' && orderStatus != 'cancelled'">
        <template v-if="orderIsRequestCancellation">
          <v-btn color="primary" class="ma-1" @click="replyOrderCancellation">
            <v-icon left>mdi-alert</v-icon>
            {{ $t("__order_action_reply_cancellation") }}
          </v-btn>
        </template>
        <template v-else>
          <template v-if="orderStatus === 'unaccepted'">
            <!-- unaccepted -->
            <v-btn color="green white--text" class="ma-1" @click="acceptOrder">
              <v-icon left>mdi-check</v-icon>
              {{ $t("__accept") }}
            </v-btn>
            <v-btn color="red white--text" class="ma-1" @click="cancelOrder">
              <v-icon left>mdi-close</v-icon>
              {{ $t("__reject") }}
            </v-btn>
          </template>
          <template v-if="orderStatus === 'accepted'">
            <!-- accepted -->
            <template
              v-if="
                orderPaidAmount < orderData.price &&
                  orderData['payment_method'] !== 'cash-on-delivery'
              "
            >
              <!-- unpaid and not cash-on-delivery、tappay -->
              <v-btn color="yellow" class="ma-1" @click="addPayment">
                <v-icon left>mdi-currency-usd</v-icon>
                {{ $t("__add_payment_form_title") }}
              </v-btn>
            </template>
            <template v-else>
              <!-- paid -->
              <v-btn color="teal white--text" class="ma-1" @click="ship">
                <v-icon left>mdi-truck</v-icon>
                {{ $t("__ship") }}
              </v-btn>
            </template>
            <v-btn color="red white--text" class="ma-1" @click="cancelOrder">
              <v-icon left>mdi-cancel</v-icon>
              {{ $t("__cancel") }}
            </v-btn>
          </template>
          <template
            v-if="orderStatus === 'shipped' || orderStatus === 'received'"
          >
            <!-- shipped or received -->
            <v-btn
              color="green white--text"
              class="ma-1"
              @click="completeOrder"
            >
              <v-icon left>mdi-check</v-icon>
              {{ $t("__complete") }}
            </v-btn>
          </template>
        </template>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import format from "date-fns/format";

export default {
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      isMobile: "isMobile",
      orderId: "order/id",
      orderData: "order/data",
      orderCreateTimeFormatted: "order/createTimeFormatted",
      orderConsumerName: "order/consumerName",
      orderStatuses: "order/statuses",
      orderStatus: "order/status",
      orderIsRequestCancellation: "order/isRequestCancellation",
      orderPayments: "order/payments",
      orderPaidAmount: "order/paidAmount",
      orderTransferInfos: "order/transferInfos"
    })
  },
  data() {
    return {
      orderItems: [],
      statuses: [],
      payments: [],
      transferInfos: [],
      transferHeaders: [
        {
          text: `${this.$t("__orderlist_id")}`,
          value: "id",
          align: "start"
        },
        {
          text: `${this.$t("__order_transferinfos_accountlast5digits")}`,
          value: "accountLast5Digits",
          align: "start"
        },
        {
          text: `${this.$t("__order_transferinfos_amount")}`,
          value: "amount",
          align: "start"
        },
        {
          text: `${this.$t("__order_transferinfos_time")}`,
          value: "timeFormatted",
          align: "start"
        },
        {
          text: `${this.$t("__order_transferinfos_status")}`,
          value: "status",
          align: "start"
        },
        {
          text: `${this.$t("__order_transferinfos_actions")}`,
          value: "actions",
          align: "start",
          sortable: false
        }
      ],
      paymentHeaders: [
        {
          text: `${this.$t("__order_payments_id")}`,
          value: "id",
          align: "start"
        },
        {
          text: `${this.$t("__order_payments_method")}`,
          value: "method",
          align: "start"
        },
        {
          text: `${this.$t("__order_payments_info")}`,
          value: "info",
          align: "start"
        },
        {
          text: `${this.$t("__order_payments_amount")}`,
          value: "amount",
          align: "start"
        },
        {
          text: `${this.$t("__order_payments_time")}`,
          value: "timeFormatted",
          align: "start"
        },
        {
          text: `${this.$t("__order_payments_status")}`,
          value: "status",
          align: "start"
        }
      ]
    };
  },
  methods: {
    updateOrderItems() {
      this.orderItems = [];
      for (const orderItem of this.orderData.items)
        this.orderItems.push(orderItem);
      this.orderItems.sort((a, b) => {
        if (a.item.name < b.item.name) return -1;
        if (a.item.name > b.item.name) return 1;
        return 0;
      });
    },
    updateStatuses() {
      this.statuses = [];
      for (const status of this.orderStatuses) {
        this.statuses.push({
          status: status.status,
          tims: status.time,
          timeFormatted: format(new Date(status.time), "yyyy/MM/dd HH:mm:ss")
        });
      }
    },
    updatePayments() {
      this.payments = [];
      for (const orderPayment of this.orderPayments) {
        const payment = {
          id: orderPayment.paymentID,
          data: orderPayment.data,
          method: orderPayment.data.method,
          info: "",
          amount: `$${orderPayment.data.amount}`,
          status: orderPayment.data.status,
          timeFormatted: format(
            new Date(orderPayment.data.update_time),
            "yyyy/MM/dd HH:mm:ss"
          )
        };
        if (payment.method === "transfer") {
          payment.info = orderPayment.data.transfer_info_id;
        }
        this.payments.push(payment);
      }
    },
    updateTransferInfos() {
      this.transferInfos = [];
      for (const orderTransferInfo of this.orderTransferInfos) {
        const transferInfo = {
          id: orderTransferInfo.id,
          data: orderTransferInfo.data,
          accountLast5Digits: orderTransferInfo.data.account_last_5_digits,
          amount: `$${orderTransferInfo.data.amount}`,
          timeFormatted: format(
            new Date(orderTransferInfo.data.create_time),
            "yyyy/MM/dd HH:mm:ss"
          ),
          status: orderTransferInfo.data.status
        };
        this.transferInfos.push(transferInfo);
      }
    },
    reloadOrder() {
      this.$emit("reloadOrder");
    },
    toPrintItems() {
      this.$emit("printItems");
    },
    acceptOrder() {
      this.$emit("acceptOrder");
    },
    cancelOrder() {
      this.$emit("cancelOrder");
    },
    ship() {
      this.$emit("ship");
    },
    completeOrder() {
      this.$emit("completeOrder");
    },
    addPayment() {
      this.$emit("addPayment");
    },
    replyOrderCancellation() {
      this.$emit("replyOrderCancellation");
    },
    replyTransferInfo(transferInfo) {
      this.$emit("replyTransferInfo", transferInfo);
    },
    getItemName(itemId) {
      const item = this.storeItems.find(i => i.id === itemId);
      if (item) {
        return item.data.info.name;
      }
      return "null";
    },
    timestamp2Date(timestamp) {
      if (timestamp) {
        return format(timestamp.toDate(), "yyyy/MM/dd");
      } else {
        return "yyyy/MM/dd";
      }
    },
    timestamp2Time(timestamp) {
      if (timestamp) {
        return format(timestamp.toDate(), "HH:mm:ss");
      } else {
        return "HH:mm:ss";
      }
    },
    toPrintOrder() {
      this.$emit("printOrder");
    },
    toPrintShippingOrder() {
      this.$emit("printShippingOrder");
    }
  },
  watch: {
    orderData: {
      deep: true,
      handler() {
        this.updateOrderItems();
        this.updateStatuses();
      }
    },
    orderPayments: {
      deep: true,
      handler() {
        this.updatePayments();
      }
    },
    orderTransferInfos: {
      deep: true,
      handler() {
        this.updateTransferInfos();
      }
    }
  }
};
</script>

<style lang="scss">
.data_table {
  width: 100%;

  td {
    margin-block: 1em;
  }
  &__title {
    text-align: left;
    vertical-align: top;
    padding-right: 2em;
    white-space: nowrap;
    @media (max-width: 376px) {
      padding-right: 1rem;
    }
  }
  &__content {
    width: 100%;
    overflow-wrap: anywhere;
  }
}
.outlined_table {
  border: 1px solid #bdbdbd;
}
.divider {
  margin: 1em 0;
}

.color_tr:nth-child(odd) {
  background-color: #c9e4f7;
}
.color_tr:nth-child(even) {
  background-color: #4daae9;
}
</style>
