<template>
  <div class="order">
    <v-container fluid>
      <v-row align="center" justify="center" dense>
        <v-col cols="12">
          <v-container>
            <v-row dense>
              <v-col cols="12">
                <template v-if="!loading">
                  <template v-if="orderIsRequestCancellation">
                    <RequestOrderCancellationCard
                      class="mb-8"
                      @replyOrderCancellation="toReplyOrderCancellation"
                    />
                  </template>
                  <template v-else-if="orderStatus == 'unaccepted'">
                    <OrderAcceptCard
                      class="mb-8"
                      @accept="toAcceptOrder"
                      @cancel="toCancelOrder"
                    />
                  </template>
                </template>
                <OrderData
                  :loading.sync="loading"
                  @reloadOrder="loadOrder"
                  @acceptOrder="toAcceptOrder"
                  @cancelOrder="toCancelOrder"
                  @ship="toShip"
                  @completeOrder="toCompleteOrder"
                  @addPayment="toAddPayment"
                  @replyOrderCancellation="toReplyOrderCancellation"
                  @replyTransferInfo="toReplyTransferInfo"
                  @printOrder="toPrintOrder"
                  @printShippingOrder="toPrintShippingOrder"
                  @printItems="toPrintItems"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <AlertDialog
      :show="showAlertDialog"
      :title="alertDialogTitle"
      :text="alertDialogText"
      :htmltext="alertDialogHTMLText"
      :oktext="$t('__confirm')"
      :canceltext="$t('__cancel')"
      @ok="alertDialogOk"
      @cancel="alertDialogCancel"
    />
    <v-dialog v-model="showAddPaymentForm" persistent max-width="600px">
      <AddPaymentForm
        :paymentMethod="addPaymentFormMethod"
        :amount="addPaymentFormAmount"
        @ok="toAddPaymentConfirm"
        @cancel="addPaymentFormCancel"
      />
    </v-dialog>
    <v-dialog
      v-model="showReplyOrderCancellationForm"
      persistent
      max-width="600px"
    >
      <ReplyOrderCancellationForm
        @ok="toReplyOrderCancellationConfirm"
        @cancel="replyOrderCancellationFormCancel"
      />
    </v-dialog>
    <v-dialog v-model="showReplyTransferInfoForm" persistent max-width="600px">
      <ReplyTransferInfoForm
        :transferInfo="replyTransferInfoFormTransferInfo"
        @ok="toReplyTransferInfoConfirm"
        @cancel="replyTransferInfoFormCancel"
      />
    </v-dialog>
    <v-dialog v-model="showShipForm" persistent max-width="600px">
      <ShipForm
        @ok="toShipConfirm"
        @cancel="shipFormCancel"
        :storeID="shipFormStoreID"
        :isLogistics="shipFormIsLogistics"
      />
    </v-dialog>
    <!-- print item tags -->
    <div id="printItem" v-if="orderData" class="d-none d-print-block">
      <v-app>
        <v-row
          v-for="item in orderData.items"
          :key="item.item_id"
          class="wrap"
          dense
        >
          <v-col cols="4" class="flex-wrap" v-for="qty in item.qty" :key="qty">
            <v-card max-width="300" min-height="168" outlined elevation="0">
              <v-card-text>
                <p class="font-weight-bold">
                  {{ item.item.name }}
                </p>
                <p>
                  {{ item.item.price | currency }}
                </p>
                <qrcode-vue
                  :value="
                    `https://t.me/${telegramBotID}?start=item_${item.item_id}`
                  "
                  size="50"
                  renderAs="svg"
                />
                <p class="text-right">
                  {{ qty + "/" + item.qty }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-app>
    </div>
    <!-- print order -->
    <print-order
      id="print-order-id"
      class="d-none d-print-block"
      :orderData="orderData"
      :orderStatus="orderStatus"
      :orderID="orderID"
      :orderConsumerName="orderConsumerName"
      :orderPayments="orderPayments"
      :orderCreateTimeFormatted="orderCreateTimeFormatted"
    />
    <!-- print shipping order -->
    <print-shipping-order
      id="print-shipping-id"
      class="d-none d-print-block"
      :orderData="orderData"
      :orderConsumerName="orderConsumerName"
      :orderID="orderID"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { format } from "date-fns";
import OrderData from "@/components/Order/OrderData";
import OrderAcceptCard from "@/components/Order/OrderAcceptCard";
import AlertDialog from "@/components/AlertDialog";
import RequestOrderCancellationCard from "@/components/Order/RequestOrderCancellationCard";
import ReplyOrderCancellationForm from "@/components/Order/ReplyOrderCancellationForm";
import ReplyTransferInfoForm from "@/components/Order/ReplyTransferInfoForm";
import AddPaymentForm from "@/components/Order/AddPaymentForm";
import ShipForm from "@/components/Order/ShipForm";
import PrintOrder from "@/components/Order/PrintOrder";
import PrintShippingOrder from "@/components/Order/PrintShippingOrder";
import QrcodeVue from "qrcode.vue";

export default {
  name: "Order",
  components: {
    OrderData,
    OrderAcceptCard,
    AlertDialog,
    RequestOrderCancellationCard,
    ReplyOrderCancellationForm,
    ReplyTransferInfoForm,
    ShipForm,
    PrintOrder,
    PrintShippingOrder,
    AddPaymentForm,
    QrcodeVue
  },
  computed: {
    ...mapGetters({
      orderID: "order/id",
      orderData: "order/data",
      orderStatus: "order/status",
      orderStatusTime: "order/statusTime",
      orderIsRequestCancellation: "order/isRequestCancellation",
      orderPaidAmount: "order/paidAmount",
      orderConsumerName: "order/consumerName",
      orderPayments: "order/payments",
      orderCreateTimeFormatted: "order/createTimeFormatted",
      orderMessages: "messages/list",
      telegramBotData: "store/messengerTelegramBotData"
    }),
    telegramBotID() {
      return this.telegramBotData ? this.telegramBotData["bot_id"] : "";
    }
  },
  data() {
    return {
      loading: true,
      showAlertDialog: false,
      alertDialogTitle: "",
      alertDialogText: "",
      alertDialogHTMLText: "",
      alertDialogAction: null,
      alertDialogData: null,
      showAddPaymentForm: false,
      addPaymentFormAmount: 0,
      addPaymentFormMethod: "",
      showReplyOrderCancellationForm: false,
      showReplyTransferInfoForm: false,
      replyTransferInfoFormTransferInfo: null,
      showShipForm: false,
      shipFormIsLogistics: false,
      shipFormStoreID: null
    };
  },
  methods: {
    loadOrder() {
      this.loading = true;
      return this.$store
        .dispatch("order/fetchOrder", {
          id: this.$route.params.id,
          onPaymentsError: err => {
            console.log("onPaymentsError", err);
          },
          onTransferInfosError: err => {
            console.log("onTransferInfosError", err);
          }
        })
        .then(() => {
          this.loadTelegramBotData();
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
          this.$router.replace("/orders");
        });
    },
    toAcceptOrder() {
      this.showAlertDialog = true;
      this.alertDialogTitle = this.$t("__order_accept_title");
      this.alertDialogText = this.$t("__order_accept_text");
      this.alertDialogAction = this.acceptOrder;
    },
    toCancelOrder() {
      this.showAlertDialog = true;
      this.alertDialogTitle = this.$t("__order_cancel_title");
      this.alertDialogText = this.$t("__order_cancel_text");
      this.alertDialogAction = this.cancelOrder;
    },
    toShip() {
      if (this.orderData["shipping_channel"] === "logistics") {
        this.shipFormIsLogistics = true;
        this.shipFormStoreID = this.orderData["store_id"];
      } else {
        this.shipFormIsLogistics = false;
        this.shipFormStoreID = null;
      }
      this.showShipForm = true;
    },
    toShipConfirm({
      logisticsNumber,
      logisticsCompanyName,
      logisticsTrackingURL
    }) {
      this.showShipForm = false;
      this.alertDialogTitle = this.$t("__order_ship_title");
      this.alertDialogHTMLText = this.$t("__order_ship_text").replace(
        "%1",
        this.orderID
      );
      if (this.orderData["shipping_channel"] === "logistics") {
        this.alertDialogHTMLText +=
          `<table>` +
          `<tr><th style='text-align:left;vertical-align:top;padding-right:0.5em;'>${this.$t(
            "__order_shipping_logistics_number"
          )}</th><td>${logisticsNumber}</td></tr>` +
          `<tr><th style='text-align:left;vertical-align:top;padding-right:0.5em;'>${this.$t(
            "__order_shipping_logistics_company_name"
          )}</th><td>${logisticsCompanyName}</td></tr>` +
          `<tr><th style='text-align:left;vertical-align:top;padding-right:0.5em;'>${this.$t(
            "__order_shipping_logistics_tracking_url"
          )}</th><td>${logisticsTrackingURL}</td></tr>` +
          `</table>`;
      } else {
        this.alertDialogHTMLText +=
          `<table>` +
          `<tr><th style='text-align:left;vertical-align:top;padding-right:0.5em;'>${this.$t(
            "__order_shipping_pickup_number"
          )}</th><td>${logisticsNumber}</td></tr>` +
          `</table>`;
      }
      this.alertDialogAction = this.ship;
      this.alertDialogData = {
        logisticsNumber,
        logisticsCompanyName,
        logisticsTrackingURL
      };
      this.showAlertDialog = true;
    },
    toCompleteOrder() {
      const orderStatus = this.orderStatus;
      if (orderStatus == "shipped") {
        const shippedDate = format(new Date(this.orderStatusTime), "T");
        const nowDate = Date.now();
        const shippedDay = Math.round(
          (nowDate - shippedDate) / 1000 / 60 / 60 / 24
        );
        const waitDay = 7 - shippedDay;
        if (waitDay > 0) {
          alert(
            this.$t("__order_unreceived_message").replaceAll(
              "%1",
              waitDay.toString()
            )
          );
          return;
        }
      }
      this.showAlertDialog = true;
      this.alertDialogTitle = this.$t("__order_complete_title");
      this.alertDialogText = this.$t("__order_complete_text");
      this.alertDialogAction = this.completeOrder;
    },
    toAddPayment() {
      this.addPaymentFormAmount = this.orderData.price - this.orderPaidAmount;
      this.addPaymentFormMethod = this.orderData.payment_method;
      this.showAddPaymentForm = true;
    },
    toAddPaymentConfirm({
      method,
      amount,
      bankCode,
      account,
      accountLast5Digits
    }) {
      this.showAddPaymentForm = false;
      this.alertDialogTitle = this.$t(`__order_pay_in_${method}_title`);
      this.alertDialogText = this.$t(`__order_pay_in_${method}_text`)
        .replace("%1", this.orderID)
        .replace("%2", amount.toString());
      this.alertDialogAction = this.addPayment;
      this.alertDialogData = {
        method,
        status: "paid",
        amount: parseFloat(amount),
        bankCode,
        account,
        accountLast5Digits
      };
      this.showAlertDialog = true;
    },
    toReplyOrderCancellation() {
      this.showReplyOrderCancellationForm = true;
    },
    toReplyOrderCancellationConfirm({ accept, description }) {
      this.showReplyOrderCancellationForm = false;
      this.alertDialogTitle = accept
        ? this.$t("__order_reply_accept_cancellation_title")
        : this.$t("__order_reply_reject_cancellation_title");
      this.alertDialogHTMLText = accept
        ? this.$t("__order_reply_accept_cancellation_text")
        : this.$t("__order_reply_reject_cancellation_text");
      this.alertDialogHTMLText = this.alertDialogHTMLText.replace(
        "%1",
        this.orderID
      );
      this.alertDialogHTMLText += `<table><tr><th style='text-align:left;vertical-align:top;padding-right:0.5em;'>${this.$t(
        "__order_reply_cancellation_text_description"
      )}</th><td>${description.replaceAll("\n", "<br/>")}</td></tr></table>`;
      this.alertDialogAction = this.replyOrderCancellation;
      this.alertDialogData = { accept, description };
      this.showAlertDialog = true;
    },
    toReplyTransferInfo(transferInfo) {
      this.replyTransferInfoFormTransferInfo = transferInfo;
      this.showReplyTransferInfoForm = true;
    },
    toReplyTransferInfoConfirm({ confirm, description }) {
      const transferInfo = this.replyTransferInfoFormTransferInfo;
      this.showReplyTransferInfoForm = false;
      this.alertDialogTitle = confirm
        ? this.$t("__order_reply_confirm_transferinfo_title")
        : this.$t("__order_reply_reject_transferinfo_title");
      this.alertDialogHTMLText = confirm
        ? this.$t("__order_reply_confirm_transferinfo_text")
        : this.$t("__order_reply_reject_transferinfo_text");
      this.alertDialogHTMLText = this.alertDialogHTMLText.replace(
        "%1",
        this.orderID
      );
      this.alertDialogHTMLText +=
        `<table>` +
        `<tr><th style='text-align:left;vertical-align:top;padding-right:0.5em;'>${this.$t(
          "__order_reply_transferinfo_text_accountlast5digits"
        )}</th><td>${transferInfo.data.account_last_5_digits}</td></tr>` +
        `<tr><th style='text-align:left;vertical-align:top;padding-right:0.5em;'>${this.$t(
          "__order_reply_transferinfo_text_amount"
        )}</th><td>$${transferInfo.data.amount}</td></tr>` +
        `<tr><th style='text-align:left;vertical-align:top;padding-right:0.5em;'>${this.$t(
          "__order_reply_transferinfo_text_description"
        )}</th><td>${description.replaceAll("\n", "<br/>")}</td></tr>` +
        `</table>`;
      this.alertDialogAction = this.replyTransferInfo;
      this.alertDialogData = { transferInfo, confirm, description };
      this.showAlertDialog = true;
    },
    acceptOrder() {
      this.loading = true;
      return this.$store
        .dispatch("order/acceptOrder")
        .then(() => {
          return this.loadOrder();
        })
        .catch(err => {
          console.error(err);
          return this.loadOrder();
        });
    },
    cancelOrder() {
      this.loading = true;
      return this.$store
        .dispatch("order/cancelOrder")
        .then(() => {
          return this.loadOrder();
        })
        .catch(err => {
          console.error(err);
          return this.loadOrder();
        });
    },
    ship({ logisticsNumber, logisticsCompanyName, logisticsTrackingURL }) {
      this.loading = true;
      return this.$store
        .dispatch("order/setOrderShipped", {
          logisticsNumber,
          logisticsCompanyName,
          logisticsTrackingURL
        })
        .then(() => {
          return this.loadOrder();
        })
        .catch(err => {
          console.error(err);
          return this.loadOrder();
        });
    },
    completeOrder() {
      this.loading = true;
      if (this.orderPaidAmount >= this.orderData["price"]) {
        return this.$store
          .dispatch("order/completeOrder")
          .then(() => {
            return this.loadOrder();
          })
          .catch(err => {
            console.error(err);
            return this.loadOrder();
          });
      } else {
        if (this.orderData["payment_method"] === "cash-on-delivery") {
          const amount = this.orderData["price"] - this.orderPaidAmount;
          this.alertDialogTitle = this.$t(
            "__order_complete_payondelivery_title"
          );
          this.alertDialogHTMLText = this.$t(
            "__order_complete_payondelivery_text"
          )
            .replace("%1", this.orderID)
            .replace("%2", amount.toString());
          this.alertDialogAction = this.addCashOnDeliveryPaymentAndCompleteOrder;
          this.alertDialogData = { method: "cash-on-delivery", amount };
          this.showAlertDialog = true;
        } else {
          alert(this.$t("__order_complete_unpaid_text"));
        }
      }
    },
    addPayment({
      method,
      status,
      amount,
      bankCode,
      account,
      accountLast5Digits
    }) {
      this.loading = true;
      return this.$store
        .dispatch("order/addPayment", {
          method,
          status,
          amount,
          bankCode,
          account,
          accountLast5Digits
        })
        .then(() => {
          return this.loadOrder();
        })
        .catch(err => {
          console.error(err);
          return this.loadOrder();
        });
    },
    addCashOnDeliveryPaymentAndCompleteOrder({ method, amount }) {
      this.loading = true;
      return this.$store
        .dispatch("order/addPayment", { method, status: "paid", amount })
        .then(() => {
          return this.$store.dispatch("order/completeOrder");
        })
        .then(() => {
          return this.loadOrder();
        })
        .catch(err => {
          console.error(err);
          return this.loadOrder();
        });
    },
    addCashOnDeliveryPayment({ amount }) {
      // console.log('addCashOnDeliveryPayment: ', order, amount)
      this.loading = true;
      return this.$store
        .dispatch("order/addCashOnDeliveryPayment", amount)
        .then(() => {
          return this.loadOrder();
        })
        .catch(err => {
          console.error(err);
          return this.loadOrder();
        });
    },
    replyOrderCancellation({ accept, description }) {
      this.loading = true;
      return this.$store
        .dispatch("order/replyOrderCancellation", { accept, description })
        .then(() => {
          return this.loadOrder();
        })
        .catch(err => {
          console.error(err);
          return this.loadOrder();
        });
    },
    replyTransferInfo({ transferInfo, confirm, description }) {
      this.loading = true;
      return this.$store
        .dispatch("order/replyTransferInfo", {
          orderID: this.orderID,
          transferInfoID: transferInfo.id,
          confirm,
          description
        })
        .then(() => {
          return this.loadOrder();
        })
        .catch(err => {
          console.error(err);
          return this.loadOrder();
        });
    },
    alertDialogOk() {
      this.showAlertDialog = false;
      this.alertDialogAction(this.alertDialogData);
    },
    alertDialogCancel() {
      this.showAlertDialog = false;
    },
    addPaymentFormCancel() {
      this.showAddPaymentForm = false;
    },
    replyOrderCancellationFormCancel() {
      this.showReplyOrderCancellationForm = false;
    },
    replyTransferInfoFormCancel() {
      this.showReplyTransferInfoForm = false;
    },
    shipFormCancel() {
      this.showShipForm = false;
    },
    toPrintItems() {
      this.$htmlToPaper("printItem");
    },
    toPrintOrder() {
      const option = {
        windowTitle: this.$t("__order")
      };
      this.$htmlToPaper("print-order-id", option);
    },
    toPrintShippingOrder() {
      const option = {
        windowTitle: " "
      };
      this.$htmlToPaper("print-shipping-id", option);
    },
    loadTelegramBotData() {
      this.$store.dispatch(
        "store/getStoreMessengers",
        this.orderData["store_id"]
      );
    }
  },
  mounted() {
    this.loadOrder();
  },
  watch: {
    $route: {
      handler() {
        this.loadOrder();
      }
    },
    orderMessages: {
      deep: true,
      handler() {
        this.loadOrder();
      }
    }
  }
};
</script>
