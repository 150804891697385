<template>
  <v-card class="mx-auto">
    <v-toolbar
      flat
      color="red darken-3"
      dark
    >
      <v-toolbar-title>{{ $t("__order_unaccepted_message") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        class="ml-2"
        color="green"
        @click="accept"
      >
        <v-icon>mdi-check</v-icon>
        {{ $t("__accept") }}
      </v-btn>
      <v-btn
        class="ml-2"
        color="red"
        @click="cancel"
      >
        <v-icon left>mdi-close</v-icon>
        {{ $t("__reject") }}
      </v-btn>
    </v-toolbar>
  </v-card>
</template>

<script>
export default {
  methods: {
    accept() {
      this.$emit('accept')
    },
    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>