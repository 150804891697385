<template>
  <v-card class="mx-auto"
    outlined 
  >
    <v-toolbar
      flat
      color="red darken-3"
      dark
    >
      <v-toolbar-title>{{ $t("__order_request_cancellation_message") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        class="ml-2"
        color="red"
        @click="replyOrderCancellation"
      >
        <v-icon left>mdi-alert</v-icon>
        {{ $t("__order_action_reply_cancellation") }}
      </v-btn>
    </v-toolbar>
  </v-card>
</template>

<script>
export default {
  methods: {
    replyOrderCancellation() {
      this.$emit('replyOrderCancellation')
    },
  }
}
</script>