<template>
  <div class="print-order">
    <v-app>
      <v-container fluid>
        <!-- store info -->
        <v-row>
          <v-col>
            <template v-if="store.storePhoto">
              <img :src="store.storePhoto" width="60%" />
            </template>
            <template v-else>
              <div class="text-h5 font-weight-bold">
                {{ store.storeName }}
              </div>
            </template>
          </v-col>
          <v-col class="text-right">
            <div class="d-flex flex-column">
              <div>
                <img id="barcode" width="80%" />
              </div>
              <div class="font-weight-bold">{{ orderCreateTimeFormatted }}</div>
              <div class="text-right" v-if="orderStatus">
                <v-chip class="ma-1" label outlined>
                  {{
                    $t("__orderlist_orderstatus") +
                      ": " +
                      $t(`__order_status_${orderStatus}`)
                  }}
                </v-chip>
              </div>
              <div class="text-right" v-if="order.paymentStatus">
                <v-chip class="ma-1" label outlined>
                  {{
                    $t("__orderlist_paymentstatus") +
                      ": " +
                      $t(`__payment_status_${order.paymentStatus}`)
                  }}
                </v-chip>
              </div>
            </div>
          </v-col>
        </v-row>
        <!-- order info -->
        <v-row>
          <v-col>
            <table class="data_table text-left">
              <tr>
                <th class="data_table__title font-weight-bold">
                  {{ $t("__order_contact_name") + ": " }}
                </th>
                <td class="data_table__content">{{ orderConsumerName }}</td>
              </tr>
              <tr>
                <th class="data_table__title font-weight-bold">
                  {{ $t("__email") + ": " }}
                </th>
                <td class="data_table__content">{{ order.ordererEmail }}</td>
              </tr>
              <tr>
                <th class="data_table__title font-weight-bold">
                  {{ $t("__order_contact_phonenumber") + ": " }}
                </th>
                <td class="data_table__content">{{ order.ordererPhone }}</td>
              </tr>
              <tr v-if="order.shippingChannel">
                <th class="data_table__title font-weight-bold">
                  {{ $t("__order_shipping_channel") + ": " }}
                </th>
                <td class="data_table__content">
                  {{ $t(`__order_shipping_channel_${order.shippingChannel}`) }}
                </td>
              </tr>
            </table>
          </v-col>
          <v-col>
            <table class="data_table text-left">
              <template v-if="order.shippingChannel === 'logistics'">
                <tr>
                  <th class="data_table__title font-weight-bold">
                    {{ $t("__order_shipping_deliveryinfo_name") + ": " }}
                  </th>
                  <td class="data_table__content">
                    {{ order.shippingChannelInfo.name }}
                  </td>
                </tr>
                <tr>
                  <th class="data_table__title font-weight-bold">
                    {{ $t("__order_shipping_deliveryinfo_phonenumber") + ": " }}
                  </th>
                  <td class="data_table__content">
                    {{ order.shippingChannelInfo.phone_number }}
                  </td>
                </tr>
                <tr>
                  <th class="data_table__title font-weight-bold">
                    {{ $t("__order_shipping_deliveryinfo_address") + ": " }}
                  </th>
                  <td class="data_table__content">
                    {{ order.shippingChannelInfo.address }}
                  </td>
                </tr>
              </template>
              <tr v-if="order.payment">
                <th class="data_table__title font-weight-bold">
                  {{ $t("__order_payment_method") + ": " }}
                </th>
                <td class="data_table__content">
                  {{ $t(`__order_payment_method_${order.payment}`) }}
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
        <!-- items info -->
        <table width="100%" class="mt-8 text-left item-table">
          <tr>
            <th class="py-5">{{ $t("__order_items") }}</th>
            <th>{{ $t("__order_items_id") }}</th>
            <th>{{ $t("__order_items_quantity") }}</th>
            <th>{{ $t("__order_items_price") }}</th>
            <th>{{ $t("__order_price_subtotal") }}</th>
          </tr>
          <tr>
            <td colspan="6">
              <v-divider />
            </td>
          </tr>
          <tr v-for="item in items" :key="item.ID">
            <td colspan="2" class="d-flex flex-column py-3">
              <v-img
                :src="item.photo"
                v-if="item.photo"
                style="width: 60px;height:60px"
              />
              <p class="mb-0 pl-2">{{ item.name }}</p>
            </td>
            <td>{{ item.ID }}</td>
            <td>{{ item.qty }}</td>
            <td>{{ item.price | currency }}</td>
            <td>{{ item.totalPrice | currency }}</td>
          </tr>
          <tr>
            <td colspan="4" class="font-weight-bold text-right pr-3">
              {{ $t("__order_price_subtotal") + ":" }}
            </td>
            <td>{{ itemsTotalPrice | currency }}</td>
          </tr>
          <tr>
            <td colspan="4" class="font-weight-bold text-right pr-3">
              {{ $t("__order_shipping_price") + ":" }}
            </td>
            <td>
              {{ order.shippingPrice ? order.shippingPrice : 0 | currency }}
            </td>
          </tr>
          <tr>
            <td colspan="4" class="font-weight-bold text-right pr-3">
              {{ $t("__order_price_total") + ":" }}
            </td>
            <td>{{ (itemsTotalPrice + order.shippingPrice) | currency }}</td>
          </tr>
        </table>
      </v-container>
    </v-app>
  </div>
</template>

<script>
import Jsbarcode from "jsbarcode";

export default {
  name: "PrintOrder",
  props: {
    orderData: {
      type: Object
    },
    orderID: {
      type: String
    },
    orderStatus: {
      type: String
    },
    orderConsumerName: {
      type: String
    },
    orderPayments: {
      type: Array
    },
    orderCreateTimeFormatted: {
      type: String
    }
  },
  data() {
    return {
      store: {},
      order: {},
      items: []
    };
  },
  computed: {
    itemsTotalPrice() {
      let totalPrice = 0;
      this.items.forEach(item => {
        totalPrice += item.totalPrice;
      });
      return totalPrice;
    }
  },
  methods: {
    loadStoreData() {
      if (this.orderData.store?.photo_urls) {
        this.store.storePhoto = this.orderData.store.photo_urls[0];
      }
      this.store.storeName = this.orderData.store.name;
    },
    loadOrderData() {
      this.order = {
        orderer: this.orderConsumerName,
        ordererEmail: this.orderData.consumer.email,
        ordererPhone: this.orderData.consumer.phone_number,
        shippingChannel: this.orderData.shipping_channel,
        shippingChannelInfo:
          this.orderData.shipping_channel === "logistics"
            ? this.orderData.shipping_delivery_info
            : {},
        payment: this.orderData.payment_method,
        orderStatus: this.orderStatus,
        paymentStatus: "unpaid",
        shippingPrice: this.orderData.shipping_price
          ? this.orderData.shipping_price
          : null
      };
    },
    loadItems() {
      for (const item of this.orderData.items) {
        const foundIndex = this.items.findIndex(i => i.ID == item.item_id);
        if (foundIndex >= 0) {
          this.items.splice(foundIndex, 1, {
            photo: item.item.photo_urls ? item.item.photo_urls[0] : null,
            name: item.item.name,
            ID: item.item_id,
            qty: item.qty,
            price: item.item.price,
            totalPrice: item.qty * item.item.price
          });
        } else {
          this.items.push({
            photo: item.item.photo_urls,
            name: item.item.name,
            ID: item.item_id,
            qty: item.qty,
            price: item.item.price,
            totalPrice: item.qty * item.item.price
          });
        }
      }
    }
  },
  watch: {
    orderData: {
      deep: true,
      handler() {
        this.loadStoreData();
        this.loadOrderData();
        this.loadItems();
        Jsbarcode("#barcode", this.orderID);
      }
    },
    orderPayments: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val.length) {
          this.order.paymentStatus = val[0].data.status;
        }
      }
    }
  }
};
</script>
